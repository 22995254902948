import ALL_AGENTS from '@/graphql/agents/query/getAllAgents.gql'

const agents = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function agentsAutocomplete(params: any) {
  const apolloQuery = ALL_AGENTS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'FULL_NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchAgentsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await agentsAutocomplete({ search: queryString })
    cb(results?.getAllAgents?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchAgentsCb')
  }
}

export function getSearchAgents(entityAgent: any, entityAgents: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchAgents('', entityAgent, entityAgents)
  }

  if (entityAgent && !agents?.value?.find((k: any) => k.value === entityAgent.uuid)) {
    agents?.value?.push({
      label: entityAgent.full_name,
      value: entityAgent.uuid
    })
  }

  return agents?.value ?? []
}

export async function searchAgents(queryString: any, entityAgent: any, entityAgents: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await agentsAutocomplete({ search: queryString })
    const remoteAgents = results?.getAllAgents?.data?.map((i: any) => {
      return {
        label: i.full_name,
        value: i.uuid
      }
    }) ?? []
    const agentsToAdd: { label: any; value: any }[] = []
    entityAgents?.forEach((j: any) => {
      if (!remoteAgents.find((k: any) => k.value === j.uuid)) {
        agentsToAdd.push({
          label: j.full_name,
          value: j.uuid
        })
      }
    })
    if (entityAgent && !remoteAgents.find((k: any) => k.value === entityAgent.uuid)) {
      agentsToAdd.push({
        label: entityAgent.full_name,
        value: entityAgent.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    agents.value = [...remoteAgents, ...agentsToAdd]
    firstTime.value = false

    if (entityAgent) {
      getSearchAgents(entityAgent, entityAgents)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchAgents')
  }
}

export function getAgentKey () {
  return key.value
}

export function initialAgents () {
  agents.value = []
  key.value = 0
  firstTime.value = true
}